import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { EventService } from "../../../core/services/event.service";
import { DialogService } from "../../../core/services/dialog.service";
import { AlertService } from "../../../core/services/alert.service";
import { ActionService } from "../../../core/services/action.service";
import { ConfigService } from "../../../core/services/config.service";
import { ActionModel } from "../../../core/models/action/action.model";

@Component({
    selector: 'app-action',
    templateUrl: './action.component.html',
    styleUrl: './action.component.scss'
})
export class ActionComponent implements OnInit {
    descriptionSection: string[] = [
        `Welcome to Actions dashboard!`,
        `Set up and manage advanced actions that digital assistant can perform in response to user interactions.`];

    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new action if it doesn't exist.`
    ]

    createActionButton: string = 'Add new action';

    actionList: ActionModel[] = [ ];
    perPage = 10; // Number of actionList per page
    currentPage = 1;
    total: number = 0;
    searchValue: string = '';

    selectedActionSubscription!: Subscription;

    constructor (
        private title: Title,
        private dialog: DialogService,
        private event: EventService,
        private alert: AlertService,
        private actionService: ActionService,
        private config: ConfigService) {
        this.title.setTitle('Actions');
    }

    async ngOnInit() {
        this.alert.showLoading();
        this.event.setCurrentTab('Actions');

        const option: NavBarOptionModel = { search: 'Type here to search action...', create: 'Create new action' };
        this.event.setContentFromNavbarOption(option);

        await this.config.fetchRequirements();
        await this.loadActionList();

        this.selectedActionSubscription = this.actionService.getActionPaginationResponse().subscribe(action => {
            if (action != null) {
                this.total = action.total;
                this.perPage = action.size;
                this.currentPage = action.page;
                this.actionList = action.actions;
                this.searchValue = action.searchValue;
            }
        })
    }

    async loadActionList() {
        const response = await this.actionService.getActionPaginationList(this.currentPage, this.perPage, this.searchValue);

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        if (response.body && response.body.actions && response.body.actions.length === 0 && this.currentPage > 1) {
            this.currentPage--;
            await this.loadActionList();
        } else {
            this.alert.close();
            this.actionService.setActionPaginationResponse(response.body);
        }
    }

    addAction() {
        this.dialog.openActionDialog();
    }

    async updateAction(action: ActionModel) {
        this.dialog.openActionDialog(action);
    }

    async deleteAction(action: ActionModel) {
        this.alert.showDelete('Delete Action?', 'Action will be permanently deleted.')
        .then(async (result: { isConfirmed: any; }) => {
            if (result.isConfirmed) {
                if (action != null && action.id) {
                    this.alert.showLoading();
                    const response = await this.actionService.deleteAction(action.id);

                    if (response.status !== 200) {
                        this.alert.showError('Error', response.message);
                        return;
                    }

                    await this.loadActionList();
                }
            }
        });
    }

    get totalPages() {
        return Math.ceil(this.total / this.perPage);
    }

    get paginatedActionList() {
        return this.actionList;
    }

    changePage(page: number | string) {
        //this.currentPage = page;
        if (typeof page === 'number') {
            this.currentPage = page;
            this.loadActionList();
        }
    }

    isActionListEmpty(): boolean {
        return !(this.actionList && this.actionList.length > 0);
    }

    // TODO create service for pagination
    get paginationRange() {
        const totalPages = this.totalPages;
        const currentPage = this.currentPage;
        const delta = 2; // Number of pages to show before and after the current page
        const range = [];

        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }

        if (currentPage - delta > 2) {
            range.unshift('...');
        }
        if (currentPage + delta < totalPages - 1) {
            range.push('...');
        }

        range.unshift(1);
        if (totalPages > 1) {
            range.push(totalPages);
        }

        return range;
    }
}
