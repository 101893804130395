<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>{{ dialogTitle }}</h2>
        <button id="id-close-assistant-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <div class="selection-container">
                <div class="assistant-list">
                    <div class="box-search">
                        <app-search-area [tab]="'Assistants'"></app-search-area>
                    </div>
                    <div class="table-name">Name</div>
                    <div class="scroll-container">
                        <div class="scroll">
                            <div id="id-assistant-item" class="assistant-item" *ngFor="let assistant of assistants; let i = index"
                                 [class.selected]="selectedAssistantIndex === i" (click)="selectAssistant(i, assistant)"> {{ assistant.name }}
                                <span class="icon-container">
                                    <img *ngIf="selectedAssistantIndex === i" [src]="checkImage" alt="Check icon" class="checkmark">
                                    <img *ngIf="selectedAssistantIndex !== i" [src]="hoverImage" alt="Hover icon" class="hover-checkmark">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
