import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { KnowledgeId, KnowledgeModel } from "../../../core/models/knowledge/knowledge.model";
import { AssistantService } from "../../../core/services/assistant.service";
import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";

@Component({
    selector: 'app-createNewAssistant-knowledge-to-assistant-modal',
    templateUrl: './add-knowledge-to-assistant-modal.component.html',
    styleUrl: './add-knowledge-to-assistant-modal.component.scss'
})
export class AddKnowledgeToAssistantModalComponent implements OnInit {
    //dialogTitle = 'Add knowledge to ';
    dialogTitle = 'Manage knowledge to ';
    saveButton = 'Save changes';
    //loupeImage: string = 'assets/icons/look.svg';

    assistantData: any
    knowledges: KnowledgeModel[] = [ ];
    assignableKnowledge: any[] = [ ];
    checkedKnowledgeIds: string[] = [];
    //isFormValid: boolean = false;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private alert: AlertService,
        private dialog: DialogService,
        private knowledge: KnowledgeService,
        private assistant: AssistantService,
        private event: EventService,
        private dialogRef: MatDialogRef<AddKnowledgeToAssistantModalComponent>) {
    }

    async ngOnInit() {
        this.assistantData = this.data;
        this.dialogTitle = this.dialogTitle + this.assistantData.name;

        const option: NavBarOptionModel = { search: 'Type here to search knowledge...', create: 'Create new knowledge' };
        this.event.setContentFromNavbarOption(option);

        await this.loadKnowledges();
    }

    async loadKnowledges() {
        this.alert.showLoading();
        const response = await this.knowledge.getKnowledgeList('');
        this.knowledges = response.body;

        const assistantKnowledgeIds = this.assistantData.knowledge
            ? this.assistantData.knowledge.map((knowledge: KnowledgeModel) => knowledge.id)
            : [];

        // Create knowledge list with selected property
        this.assignableKnowledge = this.knowledges.map(knowledge => {
            const isSelected = assistantKnowledgeIds.includes(knowledge.id);
            if (isSelected) {
                this.checkedKnowledgeIds.push(knowledge.id);
            }
            return ({
                ...knowledge,
                assignedToAssistant: isSelected
            });
        });
        this.alert.close();
    }

    toggleCheckbox(knowledge: any, event: Event): void {
        knowledge.assignedToAssistant = !knowledge.assignedToAssistant;
        this.onKnowledgeSelectionChange(knowledge.id, knowledge.assignedToAssistant);
    }

    onKnowledgeSelectionChange(knowledgeId: string, isChecked: boolean) {
        if (isChecked) {
            this.checkedKnowledgeIds.push(knowledgeId);
        } else {
            const index = this.checkedKnowledgeIds.indexOf(knowledgeId);
            if (index > -1) {
                this.checkedKnowledgeIds.splice(index, 1);
            }
        }
    }

    addNewKnowledge() {
        this.dialog.openCreateKnowledgeDialog()
    }

    isAssignableKnowledgeListEmpty(): boolean {
        return !(this.assignableKnowledge && this.assignableKnowledge.length > 0);
    }

    async save() {
        this.alert.showLoading();

        try {
            const knowledgeIds: KnowledgeId[] = [];
            this.checkedKnowledgeIds.forEach(knowledgeId => {
                knowledgeIds.push({
                    id: knowledgeId
                });
            });

            const response = await this.knowledge.assignAssistantKnowledge(this.assistantData.id, knowledgeIds);
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }

            const getAgent = await this.assistant.fetchAssistant(this.assistantData.id);
            if (getAgent.status !== 200) {
                this.alert.showError('Error', getAgent.message);
                return;
            }

            this.assistant.setSelectedAssistantResponse(getAgent.body);

            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Knowledge assigned successfully.', 'Check your knowledge list in Assistant Knowledge tab.');
        } catch (error) {
            console.error('Error assigning knowledgeList:', error);
        }
    }


    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }
}
