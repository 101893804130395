import { Component } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { AlertService } from "../../../core/services/alert.service";
import { KnowledgeModel, KnowledgeTripletModel } from "../../../core/models/knowledge/knowledge.model";

@Component({
    selector: 'app-create-knowledge-modal',
    templateUrl: './create-knowledge-modal.component.html',
    styleUrl: './create-knowledge-modal.component.scss'
})
export class CreateKnowledgeModalComponent {
    dialogTitle = 'Add new knowledge';
    nameLabel = 'Name';
    componentSelection: string[] = ['Add Text', 'Add File', 'Add Triplets'];
    componentLabel = 'Choose Component';
    knowledgeNameLabel = 'Enter knowledge name';

    selectedKnowledgeType: string = 'Text';

    knowledgeName: string = '';
    dropzoneAttachment: any;
    dropzoneFile: any;
    triplets: KnowledgeTripletModel[] = [];

    // Add Text
    textLabel = 'Text Input';
    textareaLabel = 'Enter knowledge text here...';
    knowledgeText: string = '';

    constructor(private dialog: DialogService, private knowledge: KnowledgeService, private alert: AlertService) { }

    async addKnowledge() {
        this.alert.showLoading();

        const knowledge: KnowledgeModel = {} as KnowledgeModel;
        knowledge.name = this.knowledgeName;
        knowledge.knowledgeType = this.selectedKnowledgeType;

        // TODO refactor like in edit knowledge modal
        if (this.selectedKnowledgeType === 'Text' || this.selectedKnowledgeType === 'File') {
            const formData = new FormData();
            formData.append('config', JSON.stringify(knowledge));

            if (this.selectedKnowledgeType === 'Text') {
                formData.append('document', new File([new Blob([this.knowledgeText])], 'text.txt'));
            } else if (this.selectedKnowledgeType === 'File') {
                formData.append('document', this.dropzoneFile.files[0]);
            }

            if (this.dropzoneAttachment !== undefined && this.dropzoneAttachment.files) {
                this.dropzoneAttachment.files.forEach((file: any) => {
                    formData.append('attachments', file);
                });
            }

            const response = await this.knowledge.createDocumentKnowledge(formData);

            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }
        } else if (this.selectedKnowledgeType === 'Triplets') {
            knowledge.knowledgeType = 'Triplet';
            knowledge.triplets = this.triplets;
            const response = await this.knowledge.createTripletKnowledge(knowledge);

            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }
        } else {
            this.alert.showError('Error', 'Invalid knowledge type');
            return;
        }

        const response = await this.knowledge.getKnowledgePaginationList(1, 10, '');

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        this.knowledge.setKnowledgePaginationResponse(response.body);

        this.closeDialog();
        this.alert.close();
        this.alert.showSucess('Successfully added!', 'Check knowledge availability in Learning Hub.');
    }

    handleAttachmentDropzone(dropzone: any) {
        this.dropzoneAttachment = dropzone;
    }

    handleFileDropzone(dropzone: any) {
        this.dropzoneFile = dropzone;
    }

    handleTriplets(triplets: KnowledgeTripletModel[]) {
        console.log('tripleti:', triplets);
        this.triplets = triplets;
    }

    selectComponent(component: string) {
        this.selectedKnowledgeType = component;
    }

    get isFormValid(): boolean {
        if (this.knowledgeName.trim() === '') return false;

        if (this.selectedKnowledgeType === 'Text' &&  this.knowledgeText.trim() === '') {
            return false;
        }

        if (this.selectedKnowledgeType === 'File' && (!this.dropzoneFile || this.dropzoneFile.files.length === 0)) {
            return false;
        }

        if (this.selectedKnowledgeType === 'Triplets' && this.triplets.length === 0) {
            return false;
        }

        return true;
    }

    getClass() {
        return {
            'triplets-active': this.selectedKnowledgeType === 'Triplets'
        };
    }

    closeDialog() {
        this.dialog.close();
    }
}
