import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ActionId, ActionModel } from "../../../core/models/action/action.model";
import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { ActionService } from "../../../core/services/action.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-add-action-to-assistant-modal',
    templateUrl: './add-action-to-assistant-modal.component.html',
    styleUrl: './add-action-to-assistant-modal.component.scss'
})
export class AddActionToAssistantModalComponent implements OnInit {
    //dialogTitle = 'Add action to ';
    dialogTitle = 'Manage action to ';
    saveButton = 'Save changes';
    //loupeImage: string = 'assets/icons/look.svg';

    assistantData: any
    actions: ActionModel[] = [ ];
    assignableActions: any[] = [ ];
    checkedActionIds: string[] = [];
    //isFormValid: boolean = false;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private alert: AlertService,
        private dialog: DialogService,
        private action: ActionService,
        private assistant: AssistantService,
        private event: EventService,
        private dialogRef: MatDialogRef<AddActionToAssistantModalComponent>) {
    }

    async ngOnInit() {
        this.assistantData = this.data;
        this.dialogTitle = this.dialogTitle + this.assistantData.name;

        const option: NavBarOptionModel = { search: 'Type here to search action...', create: 'Create new action' };
        this.event.setContentFromNavbarOption(option);

        await this.loadActions();
    }

    async loadActions() {
        this.alert.showLoading();
        const response = await this.action.getActionList('');
        this.actions = response.body;
        const assistantActionIds = this.assistantData.actions.map((action: ActionModel) => action.id);

        // Create action list with selected property
        this.assignableActions = this.actions.map((action: ActionModel) => {
            const isSelected = assistantActionIds.includes(action.id);
            if (isSelected) {
                this.checkedActionIds.push(action.id);
            }
            return ({
                ...action,
                assignedToAssistant: isSelected
            });
        });
        this.alert.close();
    }

    toggleCheckbox(action: any, event: Event): void {
        action.assignedToAssistant = !action.assignedToAssistant;
        this.onActionSelectionChange(action.id, action.assignedToAssistant);
    }

    onActionSelectionChange(actionId: string, isChecked: boolean) {
        if (isChecked) {
            this.checkedActionIds.push(actionId);
        } else {
            const index = this.checkedActionIds.indexOf(actionId);
            if (index > -1) {
                this.checkedActionIds.splice(index, 1);
            }
        }
    }

    addNewAction() {
        this.dialog.openActionDialog()
    }

    isAssignableActionListEmpty(): boolean {
        return !(this.assignableActions && this.assignableActions.length > 0);
    }

    async save() {
        this.alert.showLoading();

        try {
            const actionIds: ActionId[] = [];
            this.checkedActionIds.forEach(actionId => {
                actionIds.push({
                    id: actionId
                });
            });

            const response = await this.action.assignAssistantActions(this.assistantData.id, actionIds);
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }

            const getAgent = await this.assistant.fetchAssistant(this.assistantData.id);
            if (getAgent.status !== 200) {
                this.alert.showError('Error', getAgent.message);
                return;
            }

            this.assistant.setSelectedAssistantResponse(getAgent.body);

            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Actions assigned successfully.', 'Check your action list in Assistant Actions tab.');
        } catch (error) {
            console.error('Error assigning knowledgeList:', error);
        }
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }
}
