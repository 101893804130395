<div class="knowledge-container">
    <div class="box-search">
        <app-search-area [tab]="'Learning Hub'"></app-search-area>
    </div>
    <div *ngIf="searchValue && isKnowledgeListEmpty()" class="initial-container">
        <div class="description-container">
            <p>{{ noSearchResultSection[0] }}</p>
            <p>{{ noSearchResultSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!searchValue && isKnowledgeListEmpty()" class="initial-container">
        <div class="button-container">
            <button id="id-add-knowledge-button" type="button" class="add-button" (click)="addKnowledge()">
                <app-plus></app-plus>
            </button>
        </div>
        <div class="description-container">
            <p>{{ descriptionSection[0] }}</p>
            <p>{{ descriptionSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!isKnowledgeListEmpty()" class="knowledges-container">
        <div class="table-header">
            <div class="table-column name">Name</div>
            <div class="table-column channel">Knowledge Channel</div>
            <div class="table-column options">Options</div>
        </div>
        <div class="knowledge-table">
            <div *ngFor="let knowledge of paginatedKnowledgeList" class="table-row">
                <div class="table-column name">{{ knowledge.name }}</div>
                <div class="table-column channel">{{ knowledge.knowledgeType }}</div>
                <div class="table-column options">
                    <div style="display: none">
                        <img class="preparing-knowledge" [src]="preparingKnowledgeImage" alt="Preparing Knowledge">
                        <img class="warning-knowledge" [src]="warningKnowledgeImage" alt="Warning Knowledge">
                    </div>
                    <button id="id-edit-knowledge" class="edit-knowledge" (click)="updateKnowledge(knowledge)">
                        <app-edit></app-edit>
                    </button>
                    <button id="id-delete-knowledge" class="delete-knowledge" (click)="deleteKnowledge(knowledge)">
                        <app-delete></app-delete>
                    </button>
                </div>
            </div>
        </div>
        <div class="bottom-container">
            <button id="id-create-new-knowledge" type="button" class="create-new-knowledge" (click)="addKnowledge()">
                <app-plus></app-plus>
                <span>{{ createKnowledgeButton }}</span>
            </button>
            <div class="pagination">
                <button *ngFor="let page of paginationRange"
                        [class.active]="currentPage === page"
                        (click)="changePage(page)"
                        [disabled]="page === '...'">
                    {{ page }}
                </button>
            </div>
        </div>
    </div>
</div>
