<div class="dialog-header">
    <h2>{{ dialogTitle }}</h2>
    <button id="id-close-knowass-dialog" type="button" class="close-button" (click)="closeDialog()">
        <app-close></app-close>
    </button>
</div>
<mat-dialog-content>
    <div class="dialog-container">
        <div *ngIf="isAssignableActionListEmpty()" class="initial-container">
            <div class="button-container">
                <button id="id-add-knowledge-button" type="button" class="add-button" (click)="addNewAction()">
                    <app-plus></app-plus>
                </button>
            </div>
            <div class="description-container">
                <p>Currently, there are no action items created!<br> Create action
                    items that you can assign to the assistant!</p>
            </div>
        </div>
        <div *ngIf="!isAssignableActionListEmpty()" class="box-search">
            <app-search-area [tab]="'Actions'"></app-search-area>
        </div>
        <div *ngIf="!isAssignableActionListEmpty()" class="selection-container">
            <div class="knowledge-table">
                <div class="table-header">
                    <div class="table-column name">Name</div>
                    <div class="table-column options"></div>
                </div>
                <div class="scroll-container">
                    <div class="scroll">
                        <div *ngFor="let action of assignableActions; let i = index" class="table-row" (click)="toggleCheckbox(action, $event)">
                            <div class="table-column name">{{ action.name }}</div>
                            <div class="table-column options">
                                <!--<img [src]="loupeImage" alt="Loupe icon">-->
                                <input type="checkbox"
                                       [value]="action.id"
                                       [id]="'action-' + action.id"
                                       [(ngModel)]="action.assignedToAssistant">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!isAssignableActionListEmpty()" align="end" class="dialog-footer">
    <button id="id-save-updated-knowledge" type="button" class="save-button" (click)="save()">{{ saveButton }}</button>
</mat-dialog-actions>
