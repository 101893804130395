// without information about actions, languages, shortcuts, knowledge, voices
export class AgentConfigurationModel {
    actionEnabled: boolean = false;
    age: number = 0;
    analyticEnabled: boolean = false;
    answerLength: number = 0;
    authenticationEnabled: boolean = false;
    behavior: string = '';
    cacheEnabled: boolean = false;
    communicationStyle: string = '';
    gender: string = '';
    id: string = '';
    interests: string[] = [];
    knowledgeDatabaseType: string = '';
    knowledgeScope: string = '';
    location: string = '';
    nResults: number = 0;
    nResultsAttachment: number = 0;
    nStructuredResults: number = 0;
    name: string = '';
    promptId: string = '';
    relatedQuestionsEnabled: string = '';
    responseFormat: string = '';
    retrievalType: string = '';
    similarityThreshold: number = 0;
    temperature: number = 0;
    textSplitterOverlap: number = 0;
    textSplitterTokenLength: number = 0;
    textSplitterType: string = '';
    timezone: string = '';

    constructor() {}

    /*constructor(actionEnabled: boolean, age: number, analyticEnabled: boolean, answerLength: number,
                behavior: string, cacheEnabled: boolean, communicationStyle: string, gender: string,
                id: string, interests: string[], knowledgeDatabaseType: string, knowledgeScope: string,
                location: string, nResults: number, nResultsAttachment: number, nStructuredResults: number,
                name: string, promptId: string, relatedQuestionsEnabled: string, responseFormat: string,
                retrievalType: string, similarityThreshold: number, temperature: number, textSplitterOverlap: number,
                textSplitterTokenLength: number, textSplitterType: string, timezone: string) {
        this.actionEnabled = actionEnabled;
        this.age = age;
        this.analyticEnabled = analyticEnabled;
        this.answerLength = answerLength;
        this.behavior = behavior;
        this.cacheEnabled = cacheEnabled;
        this.communicationStyle = communicationStyle;
        this.gender = gender;
        this.id = id;
        this.interests = interests;
        this.knowledgeDatabaseType = knowledgeDatabaseType;
        this.knowledgeScope = knowledgeScope;
        this.location = location;
        this.nResults = nResults;
        this.nResultsAttachment = nResultsAttachment;
        this.nStructuredResults = nStructuredResults;
        this.name = name;
        this.promptId = promptId;
        this.relatedQuestionsEnabled = relatedQuestionsEnabled;
        this.responseFormat = responseFormat;
        this.retrievalType = retrievalType;
        this.similarityThreshold = similarityThreshold;
        this.temperature = temperature;
        this.textSplitterOverlap = textSplitterOverlap;
        this.textSplitterTokenLength = textSplitterTokenLength;
        this.textSplitterType = textSplitterType;
        this.timezone = timezone;
    }*/
}
