import { Injectable } from '@angular/core';

import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor(private auth: AuthService) { }

    async setError(e: any, body: any) {
        if (e.status === 403) {
            await this.auth.logout();
        }
        return {
            status: e.status,
            message: e.message,
            body: body
        };
    }
}
